import { createApi } from '@reduxjs/toolkit/query/react';

import type { IClient, ICanDelete } from 'src/types/clients';
import { authQuery } from './baseQuery';

const CLIENTS_SERVICE = import.meta.env.VITE_API_CLIENTS_SERVICE;

export const clientsAPISlice = createApi({
  reducerPath: 'presentation-service-clients-api',
  tagTypes: ['Clients'],
  baseQuery: authQuery,
  endpoints: (builder) => ({
    getClients: builder.query<{ data: IClient[] }, void | string[]>({
      query: (attributes) => `/clients${attributes ? `?attributes=${attributes.join(',')}` : ''}`,
      providesTags: ['Clients'],
    }),

    getClient: builder.query<{ data: IClient }, string>({
      query: (clientId) => `/clients/${clientId}`,
      providesTags: ['Clients'],
    }),

    addClient: builder.mutation<
      { id: string; slug: string; readableId: string; name: string },
      Partial<IClient>
    >({
      query: (requestData) => ({
        url: `${CLIENTS_SERVICE}`,
        method: 'POST',
        body: requestData,
      }),
      // invalidatesTags: ["Clients"],
      async onQueryStarted(requestData, { dispatch, queryFulfilled }) {
        try {
          const queryResponse = await queryFulfilled;

          dispatch(
            clientsAPISlice.util.updateQueryData('getClients', undefined, (allClientsDraft) => {
              const newClient = {
                ...requestData,
                ...queryResponse.data,
              } as IClient;

              allClientsDraft.data.push(newClient);
            })
          );
        } catch {
          console.log('Error creating client!');
        }
      },
    }),

    updateClient: builder.mutation<string, { id: string; body: Partial<IClient> }>({
      query: ({ id, body }) => ({
        url: `${CLIENTS_SERVICE}/${id}`,
        method: 'PATCH',
        body,
      }),
      // invalidatesTags: ["Clients"],
      async onQueryStarted({ id, body }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;

          dispatch(
            clientsAPISlice.util.updateQueryData('getClients', undefined, (allClientsDraft) => {
              const clientIndex = allClientsDraft.data.findIndex((item) => item.id === id);

              if (clientIndex !== -1) {
                allClientsDraft.data[clientIndex] = {
                  ...allClientsDraft.data[clientIndex],
                  ...body,
                };
              }
            })
          );
        } catch {
          console.log('Error updating client!');
        }
      },
    }),

    deleteClient: builder.mutation<ICanDelete, string>({
      query: (id) => ({
        url: `${CLIENTS_SERVICE}/${id}`,
        method: 'DELETE',
      }),
      // invalidatesTags: ["Clients"],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;

          dispatch(
            clientsAPISlice.util.updateQueryData('getClients', undefined, (allClientsDraft) => {
              const clientIndex = allClientsDraft.data.findIndex((item) => item.id === id);

              if (clientIndex !== -1) {
                allClientsDraft.data.splice(clientIndex, 1);
              }
            })
          );
        } catch {
          console.log('Error updating client!');
        }
      },
    }),
  }),
});

export const {
  useGetClientsQuery,
  useAddClientMutation,
  useUpdateClientMutation,
  useDeleteClientMutation,
  useGetClientQuery,
} = clientsAPISlice;

// core (MUI)
import { frFR as frFRCore, deDE as deDECore, svSE as svSECore } from '@mui/material/locale';

// date pickers (MUI)
import {
  enUS as enUSDate,
  frFR as frFRDate,
  deDE as deDEDate,
  svSE as svSEDate,
} from '@mui/x-date-pickers/locales';

// data grid (MUI)
import {
  enUS as enUSDataGrid,
  frFR as frFRDataGrid,
  deDE as deDEDataGrid,
  svSE as svSEDataGrid,
} from '@mui/x-data-grid/locales';

// ----------------------------------------------------------------------

export const allLangs = [
  {
    value: 'en',
    label: 'English',
    countryCode: 'GB',
    adapterLocale: 'en',
    numberFormat: { code: 'en-US', currency: 'USD' },
    systemValue: {
      components: { ...enUSDate.components, ...enUSDataGrid.components },
    },
  },
  {
    value: 'se',
    label: 'Swedish',
    countryCode: 'SE',
    adapterLocale: 'se',
    numberFormat: { code: 'sv-SE', currency: 'SEK' },
    systemValue: {
      components: { ...svSECore.components, ...svSEDate.components, ...svSEDataGrid.components },
    },
  },
  {
    value: 'fr',
    label: 'French',
    countryCode: 'FR',
    adapterLocale: 'fr',
    numberFormat: { code: 'fr-Fr', currency: 'EUR' },
    systemValue: {
      components: { ...frFRCore.components, ...frFRDate.components, ...frFRDataGrid.components },
    },
  },
  {
    value: 'de',
    label: 'Deutsch',
    countryCode: 'DE',
    adapterLocale: 'de',
    numberFormat: { code: 'de-DE', currency: 'EUR' },
    systemValue: {
      components: { ...deDECore.components, ...deDEDate.components, ...deDEDataGrid.components },
    },
  },
];

/**
 * Country code:
 * https://flagcdn.com/en/codes.json
 *
 * Number format code:
 * https://gist.github.com/raushankrjha/d1c7e35cf87e69aa8b4208a8171a8416
 */

import { paths } from 'src/routes/paths';
import type { TFunction } from 'i18next';

import { Iconify } from 'src/components/iconify';
import { UserRoles } from 'src/types/common';

export function navData(t: TFunction<any, any>) {
  return [
    {
      subheader: t('common.workspace'),
      items: [
        {
          title: t('sidebar.menuItems.dashboard'),
          path: paths.dashboard.root,
          icon: <Iconify width={18} icon="lucide:layout-dashboard" />,
          roles: [
            UserRoles.CLIENT_MANAGER,
            UserRoles.CLIENT_OPERATOR,
            UserRoles.KEY_ACCOUNT_MANAGER,
          ],
          dataTestId: 'menu-item-dashboard',
        },
        {
          title: t('sidebar.menuItems.invoices'),
          path: paths.dashboard.invoices,
          icon: <Iconify width={18} icon="lucide:receipt" />,
          roles: [UserRoles.CLIENT_MANAGER, UserRoles.CLIENT_OPERATOR, UserRoles.CUSTOMER_OPERATOR],
          dataTestId: 'menu-item-invoices',
        },
        {
          title: t('sidebar.menuItems.customers'),
          path: paths.dashboard.customers,
          icon: <Iconify width={18} icon="lucide:users" />,
          roles: [UserRoles.CLIENT_MANAGER, UserRoles.CLIENT_OPERATOR, UserRoles.CUSTOMER_OPERATOR],
          dataTestId: 'menu-item-customers',
        },
        {
          title: t('sidebar.menuItems.payments'),
          path: paths.dashboard.payments,
          icon: <Iconify width={18} icon="lucide:wallet" />,
          roles: [UserRoles.CLIENT_MANAGER, UserRoles.CLIENT_OPERATOR, UserRoles.CUSTOMER_OPERATOR],
          dataTestId: 'menu-item-payments',
        },
        // { title: t('sidebar.menuItems.imports'), path: paths.dashboard.imports, icon: <Iconify width={18} icon="lucide:import" /> },
        // { title: t('sidebar.menuItems.failures'), path: paths.dashboard.failures, icon: <Iconify width={18} icon="lucide:ban" />},
        // { title: t('sidebar.menuItems.analytics'), path: paths.dashboard.analytics, icon: <Iconify width={18} icon="lucide:chart-no-axes-combined" /> },
        // { title: t('sidebar.menuItems.reports'), path: paths.dashboard.reports, icon: <Iconify width={18} icon="lucide:notebook-text" /> },
      ],
    },
    {
      subheader: t('common.management'),
      items: [
        {
          title: t('sidebar.menuItems.users'),
          path: paths.dashboard.users,
          icon: <Iconify width={18} icon="lucide:user-cog" />,
          roles: [UserRoles.CLIENT_OPERATOR],
          dataTestId: 'menu-item-users',
        },
        {
          title: t('sidebar.menuItems.clients'),
          path: paths.dashboard.clients,
          icon: <Iconify width={18} icon="lucide:circle-user-round" />,
          roles: [UserRoles.CLIENT_OPERATOR],
          dataTestId: 'menu-item-clients',
        },
        {
          title: t('sidebar.menuItems.webhooks'),
          path: paths.dashboard.webhooks.subscriptions,
          icon: <Iconify width={18} icon="lucide:webhook" />,
          roles: [UserRoles.CLIENT_OPERATOR],
          dataTestId: 'menu-item-webhooks',
          children: [
            {
              title: t('sidebar.menuItems.webhookSubscriptions'),
              path: paths.dashboard.webhooks.subscriptions,
              icon: <Iconify width={18} icon="lucide:network" />,
              roles: [UserRoles.CLIENT_OPERATOR],
              dataTestId: 'menu-item-subscriptions',
            },
            {
              title: t('sidebar.menuItems.webhookEvents'),
              path: paths.dashboard.webhooks.history,
              icon: <Iconify width={18} icon="lucide:history" />,
              roles: [UserRoles.CLIENT_OPERATOR],
              dataTestId: 'menu-item-history',
            },
          ],
        },
      ],
    },
  ];
}

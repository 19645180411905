import { lazy, Suspense } from 'react';
import type { RouteObject } from 'react-router';
import { Outlet } from 'react-router';

import { DashboardLayout } from 'src/layouts/dashboard';
import { LoadingScreen } from 'src/components/loading-screen';
import { AuthGuard, RoleBasedGuard } from 'src/auth/guard';
import { UserRoles } from 'src/types/common';

const Dashboard = lazy(() => import(`../../pages/dashboard/DashboardPage`));
const Customers = lazy(() => import(`../../pages/customers/CustomersPage`));
const CustomerProfile = lazy(() => import(`../../pages/customerProfile/CustomerProfilePage`));
const Payments = lazy(() => import(`../../pages/payments/PaymentsPage`));
const PaymentMatching = lazy(() => import(`../../pages/payment-matching/PaymentMatchingPage`));
// const Imports = lazy(() => import(`../../pages/imports/ImportsPage`));
// const Failures = lazy(() => import(`../../pages/failures/FailuresPage`));
// const Analytics = lazy(() => import(`../../pages/analytics/AnalyticsPage`));
// const Reports = lazy(() => import(`../../pages/reports/ReportsPage`));
const Users = lazy(() => import(`../../pages/users/UsersPage`));
const Clients = lazy(() => import(`../../pages/clients/ClientsPage`));
const Invoices = lazy(() => import(`../../pages/invoices/InvoicesPage`));
const InvoiceOverview = lazy(() => import(`../../pages/invoiceOverview/InvoiceOverview`));
// const Profile = lazy(() => import(`../../pages/profile/ProfilePage`));
// const Security = lazy(() => import(`../../pages/security/SecurityPage`));
// const Settings = lazy(() => import(`../../pages/settings/SettingsPage`));
const WebhookSubscriptions = lazy(
  () => import(`../../pages/webhook-subscriptions/WebhookSubscriptionsPage`)
);
const WebhookEvents = lazy(() => import(`../../pages/webhook-events/WebhookEventsPage`));

const dashboardLayout = () => (
  <DashboardLayout>
    <Suspense fallback={<LoadingScreen />}>
      <Outlet />
    </Suspense>
  </DashboardLayout>
);

const COMMON_ROLES = [
  UserRoles.CLIENT_MANAGER,
  UserRoles.CLIENT_OPERATOR,
  UserRoles.CUSTOMER_OPERATOR,
];

export const dashboardRoutes: RouteObject[] = [
  {
    path: '',
    element: <AuthGuard>{dashboardLayout()}</AuthGuard>,
    children: [
      { element: <Dashboard />, index: true },
      {
        path: 'customers',
        element: (
          <RoleBasedGuard acceptRoles={COMMON_ROLES} hasContent>
            <Customers />
          </RoleBasedGuard>
        ),
      },
      {
        path: 'customer/:id',
        element: (
          <RoleBasedGuard acceptRoles={COMMON_ROLES} hasContent>
            <CustomerProfile />
          </RoleBasedGuard>
        ),
      },
      {
        path: 'invoices',
        element: (
          <RoleBasedGuard acceptRoles={COMMON_ROLES} hasContent>
            <Invoices />
          </RoleBasedGuard>
        ),
      },
      {
        path: 'invoice/:id',
        element: (
          <RoleBasedGuard acceptRoles={COMMON_ROLES} hasContent>
            <InvoiceOverview />
          </RoleBasedGuard>
        ),
      },
      {
        path: 'payments',
        element: (
          <RoleBasedGuard acceptRoles={COMMON_ROLES} hasContent>
            <Payments />
          </RoleBasedGuard>
        ),
      },
      {
        path: 'payment-matching',
        element: (
          <RoleBasedGuard acceptRoles={COMMON_ROLES} hasContent>
            <PaymentMatching />
          </RoleBasedGuard>
        ),
      },
      {
        path: 'webhooks',
        element: (
          <RoleBasedGuard acceptRoles={[UserRoles.CLIENT_OPERATOR]} hasContent>
            <WebhookSubscriptions />
          </RoleBasedGuard>
        ),
      },
      {
        path: 'webhooks/history',
        element: (
          <RoleBasedGuard acceptRoles={[UserRoles.CLIENT_OPERATOR]} hasContent>
            <WebhookEvents />
          </RoleBasedGuard>
        ),
      },
      {
        path: 'users',
        element: (
          <RoleBasedGuard acceptRoles={[UserRoles.CLIENT_OPERATOR]} hasContent>
            <Users />
          </RoleBasedGuard>
        ),
      },
      {
        path: 'clients',
        element: (
          <RoleBasedGuard acceptRoles={[UserRoles.CLIENT_OPERATOR]} hasContent>
            <Clients />
          </RoleBasedGuard>
        ),
      },

      // { path: 'imports', element: <Imports /> },
      // { path: 'failures', element: <Failures /> },
      // { path: 'analytics', element: <Analytics /> },
      // { path: 'reports', element: <Reports /> },
      // { path: 'profile', element: <Profile /> },
      // { path: 'security', element: <Security /> },
      // { path: 'settings', element: <Settings /> },
    ],
  },
];

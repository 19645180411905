import type { ICustomer } from './customers';

export type IPayment = {
  id: string;
  invoiceId?: string;
  status: PaymentStatus;
  statusReason: PaymentStatusReason;
  amount: number;
  currency: string;
  paidDate: string;
  transactionReference: string;
  paymentReference?: string;
  transactionType?: PaymentTransactionType;
  clientId?: string;
  transactionProvider: string;
  invoiceNo?: string;
  rawFormat?: IRawFormat;
  customer?: ICustomer;
  customerSortProp: string;
  // deviceType: string;
  // callbackUrl: string;
  // payerAlias: string;
  // payeeAlias: string;
  // errorMessage: string;
  // token: string;
  // paymentBreakdown: {
  //     fee: number,
  //     capital: number,
  //     interest: number
  // },
  // createdAt: string;
  // updatedAt: string;
  // deletedAt: string;
  // address?: string;
  // phoneNumber?: string;
  // message?: string;
  // customerSSN?: string;
  // customerId: string;
  // customerName: string;
};

export type IRawFormat = {
  creditor: string;
  messageId: string;
  bookingDate: string;
  creditorBank: {
    id: string;
    schemaName: string;
  };
  debitorBank: {
    id: string;
    schemaName: string;
  };
  debitorName: string;
  debitorAddress: string;
  instructionId: string;
  accountDetails: {
    id: string;
    name: string;
    currency: string;
  };
  parsedFileName: string;
  creationDateTime: string;
  accountServicerReference: string;
};

export enum PaymentStatus {
  NotProcessed = 'not_processed',
  MatchedManual = 'matched_manual',
  MatchedAuto = 'matched_auto',
  NotMatched = 'not_matched',
}

export enum PaymentStatusReason {
  AlreadyPaid = 'already_paid',
  InDebtCollection = 'in_debt_collection',
  DuplicatePaymentReference = 'duplicate_payment_reference',
  PaymentIntention = 'payment_intention',
  CurrencyMismatch = 'currency_mismatch',
}

export enum PaymentTransactionType {
  GoodWill = 'good_will',
  CreditLoss = 'credit_loss',
  CreditAmount = 'credit_amount',
  DirectPayment = 'direct_payment',
  Cancelled = 'cancelled',
  Bank = 'bank',
  PSP = 'psp',
  DirectDebit = 'direct_debit',
}

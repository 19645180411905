import { createApi } from '@reduxjs/toolkit/query/react';

import { authQuery } from './baseQuery';
import type { TableDataResponse } from 'src/types/common';
import { IWebhookSubscription, IWebhookEvent } from 'src/types/webhooks';

const baseUrl = (import.meta.env.VITE_API_PRESENTATION_SERVICE as string).replace(
  'bop/presentation',
  'v1'
);

export const webhooksAPISlice = createApi({
  reducerPath: 'presentation-service-webhooks-api',
  tagTypes: ['Subscriptions', 'WebhookHistory'],
  baseQuery: (args: any, api: any, extraOptions: any) =>
    authQuery(args, api, (extraOptions = { ...extraOptions, baseUrl: baseUrl })),
  endpoints: (builder) => ({
    getAllSubscriptions: builder.query<TableDataResponse<IWebhookSubscription>, void>({
      query: () => '/subscriptions',
      providesTags: ['Subscriptions'],
    }),

    getSubscriptionById: builder.query<IWebhookSubscription, string>({
      query: (id: string) => `/subscriptions/${id}`,
      providesTags: ['Subscriptions'],
    }),

    createSubscription: builder.mutation({
      query: (body) => ({
        url: '/subscriptions',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Subscriptions'],
    }),

    editSubscription: builder.mutation({
      query: (body) => ({
        url: `/subscriptions/${body.id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Subscriptions'],
    }),

    getAllWebhookEvents: builder.query<TableDataResponse<IWebhookEvent>, void>({
      query: () => '/event-webhook',
      providesTags: ['WebhookHistory'],
    }),

    getWebhookEventById: builder.query<IWebhookEvent, string>({
      query: (id: string) => `/event-webhook/${id}`,
      providesTags: ['WebhookHistory'],
    }),

    retryWebhook: builder.mutation({
      query: (body) => ({
        url: `/event-webhook/retry`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['WebhookHistory'],
    }),

    deleteSubscription: builder.mutation({
      query: (id) => ({
        url: `/subscriptions/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Subscriptions'],
    }),
  }),
});

export const {
  useGetAllSubscriptionsQuery,
  useGetSubscriptionByIdQuery,
  useCreateSubscriptionMutation,
  useEditSubscriptionMutation,
  useGetAllWebhookEventsQuery,
  useGetWebhookEventByIdQuery,
  useRetryWebhookMutation,
  useDeleteSubscriptionMutation,
} = webhooksAPISlice;
